<template>
  <v-card>
    <v-form ref="form">
      <v-app-bar color="deep-purple accent-4" dense>
        <v-toolbar-title>속성 편집</v-toolbar-title>
      </v-app-bar>
      <v-card-subtitle>&nbsp;</v-card-subtitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="attr.name"
              :readonly="attr.edit"
              outlined
              label="이름"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="attr.key"
              :readonly="attr.edit"
              :rules="rules.key"
              outlined
              label="Key"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="attr.description"
              :readonly="attr.edit"
              outlined
              rows="4"
              style="font-size: 0.9rem"
              label="Description"
            ></v-textarea>
          </v-col>

          <v-col cols="12" v-if="!attr.options">
            <v-textarea
              v-if="attr.key === 'client_id'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.numeric]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cert_key' && !Authorization.isAdminId()"
              value="***************"
              :rules="rules.value.required"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'cert_key' && Authorization.isAdminId()"
              v-model="attr.value"
              :rules="rules.value.required"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'sid'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.sid]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'snm'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.snm]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'tel'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.tel]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'adr'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.adr]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'adr_detail'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.adr_detail]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'skind'"
              v-model="attr.value"
              :rules="rules.value.required"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'park'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.park]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'utime'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.utime]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'latitude'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.lat]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'longitude'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.lng]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <v-textarea
              v-if="attr.key === 'memo'"
              v-model="attr.value"
              :rules="[...rules.value.required, ...rules.value.memo]"
              :readonly="!attr.edit"
              outlined
              label="Value"
            ></v-textarea>
            <!-- 속성 추가시 value UI -->
            <!-- <v-textarea
              v-if="attr.edit === false"
              v-model="attr.value"
              :rules="rules.value.required"
              :readonly="attr.edit"
              outlined
              label="Value"
            ></v-textarea> -->
          </v-col>
          <!-- select box로 선택해야 하는 정보들 : hol, direction, roof, pay, pilot, del -->
          <v-col cols="12" v-if="attr.options">
            <v-select
              v-model="attr.value"
              :rules="rules.value.required"
              :items="attr.options"
              :readonly="!attr.edit"
              label="Value"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="editElement"> Edit </v-btn>
        <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import validator from "../../app.validator.js";
import Authorization from "../../env.authorization.js";

export default {
  name: "ManagementsMetadata",

  props: {},
  data: () => ({
    Authorization: Authorization,
    rules: {
      key: [validator.required("속성 이름이 필요합니다.")],
      value: {
        required: [validator.required("속성 값이 필요합니다.")],
        numeric: [validator.numeric("숫자만 입력하실 수 있습니다.")],
        sid: [
          validator.sid(
            "충전소ID는 영문 또는 숫자만 사용 가능하며, 최대 10자까지 입력하실 수 있습니다."
          ),
        ],
        snm: [validator.maxLength("최대 100자까지 입력하실 수 있습니다.", 100)],
        tel: [validator.tel("올바른 연락처 형식을 입력해주세요.")],
        adr: [validator.maxLength("최대 512자까지 입력하실 수 있습니다.", 512)],
        adr_detail: [
          validator.maxLength("최대 255자까지 입력하실 수 있습니다.", 255),
        ],
        park: [validator.maxLength("최대 10자까지 입력하실 수 있습니다.", 10)],
        utime: [validator.maxLength("최대 50자까지 입력하실 수 있습니다.", 50)],
        lat: [
          validator.lat("올바른 위도를 입력해주세요."),
          validator.maxLength("최대 20자까지 입력하실 수 있습니다.", 20),
        ],
        lng: [
          validator.lng("올바른 경도를 입력해주세요."),
          validator.maxLength("최대 20자까지 입력하실 수 있습니다.", 20),
        ],
        memo: [
          validator.maxLength("최대 256자까지 입력하실 수 있습니다.", 256),
        ],
        operator: [
          validator.maxLength("최대 100자까지 입력하실 수 있습니다.", 100),
        ],
      },
    },
    attr: {},
    initAttr: {},
  }),

  methods: {
    active(args, action) {
      console.log(this.$options.name, "active", args);
      this.attr = args;
      this.initAttr = { ...args };
      if (
        this.attr.key === "client_id" ||
        this.attr.key === "cert_key" ||
        this.attr.key === "sid" ||
        this.attr.key === "snm" ||
        this.attr.key === "skind" ||
        this.attr.key === "operator"
      ) {
        this.attr.edit = false;
        if (this.attr.value === undefined || action === "create") {
          this.attr.edit = true;
        }
      }
    },

    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.attr.value = this.initAttr.value;
      this.$emit("inactive", args);
    },

    editElement() {
      if (!this.$refs.form.validate()) return;
      this.inactive({
        edit: this.attr.edit,
        name: this.attr.name,
        key: this.attr.key,
        value: this.attr.value,
        options: this.attr.options,
        description: this.attr.description,
      });
    },
  },
};
</script>

<style></style>
