import Vue from "vue";
import Authorization from "@/app/env.authorization.js";

const EventBus = new Vue();

// const Stomp = require("stompjs");
const StompJs = require("@stomp/stompjs");
const connectUri = process.env.VUE_APP_OCPP_WS_SERVER + "/connect/websocket";
const subscriptionUri = "/topic";
const publishUri = "/app";

let stompClient = null;

let subscribeList = []; // ?
// const publishList = [];

const WEBSOCKET = {
  $on(name, func) {
    EventBus.$on(name, func); // 매핑
  },
  $off(name) {
    EventBus.$off(name);
  },

  headers(h) {
    return Authorization.getAuthorizationHeader(h);
  },

  connect() {
    // alert("connect...");
    // stompClient = Stomp.client(connectUri);
    // // stompClient.heartbeat.outgoing = 1000;
    // // stompClient.heartbeat.incoming = 1000;
    // // stompClient.reconnect_delay = 1000;

    // stompClient.connect(
    //   WEBSOCKET.headers(),
    //   () => {
    //     EventBus.$emit("onStartup", { websocket: WEBSOCKET, message: {} });
    //   },
    //   (tx) => {
    //     EventBus.$emit("onShutdown", { websocket: WEBSOCKET, message: tx });
    //   }
    // );

    stompClient = new StompJs.Client({
      brokerURL: connectUri,
      connectHeaders: WEBSOCKET.headers(),
      // the client supports automatic reconnecting in case of a connection failure.
      reconnectDelay: 1000,
      // the client & server send heartbeats(client sending: outgoing, server sending: incoming)
      heartbeatIncoming: 3000,
      heartbeatOutgoing: 3000,
      // Subscriptions should be done inside onConnect as those need to reinstated when the broker reconnects
      onConnect: function () {
        // this is needed because this will be executed after a (re)connect
        EventBus.$emit("onStartup", { websocket: WEBSOCKET, message: {} });
      },
      onStompError: function (frame) {
        // console.log("Broker reported error: " + frame.headers["message"]);
        // console.log("Additional details: " + frame.body)
        EventBus.$emit("onShutdown", {
          websocket: WEBSOCKET,
          message: frame.headers["message"],
        });
      },
    });

    stompClient.activate();
  },

  refresh(management) {
    const subUri1 = "/ocpp1.6/" + management.id + "/*";
    const subUri2 = "/ocpp2.0.1/" + management.id + "/*";

    if (subscribeList.find((element) => element === subUri1) == undefined) {
      // alert('subscribe1');
      subscribeList.push(subUri1);
      WEBSOCKET.subscribe(subUri1);
      // var pubUri2 = "/ocpp2.0.1/" + management.id + "/"+management.id;
      // WEBSOCKET.publish({target : pubUri2},  "SUBSCRIPTION")
    }

    if (subscribeList.find((element) => element === subUri2) == undefined) {
      // alert('subscribe2');
      subscribeList.push(subUri2);
      WEBSOCKET.subscribe(subUri2);
      // var pubUri1 = "/ocpp1.6/" + management.id + "/"+management.id;
      // var pubUri2 = "/ocpp2.0.1/" + management.id + "/"+management.id;
      // WEBSOCKET.publish({target : pubUri1},  "SUBSCRIPTION")
      // WEBSOCKET.publish({target : pubUri2},  "SUBSCRIPTION")
    }

    const pubUri1 = "/ocpp1.6/" + management.id + "/" + management.id;
    const pubUri2 = "/ocpp2.0.1/" + management.id + "/" + management.id;
    WEBSOCKET.publish({ target: pubUri1 }, "SUBSCRIPTION");
    WEBSOCKET.publish({ target: pubUri2 }, "SUBSCRIPTION");
  },

  subscribe(subUri) {
    stompClient.subscribe(
      // /topic/ocpp1.6/999bcbf7-f8b6-4ad4-b9dd-24d2e08948bd/*
      subscriptionUri + subUri,
      (m) => {
        let body = null;
        try {
          body = JSON.parse(m.body);
        } catch {
          body = m.body;
        }

        const receivedMessage = {
          id: m.headers["message-id"],
          target: m.headers.ocppTarget,
          operation: m.headers.ocppOperation,
          action: m.headers.ocppVersion + "/" + m.headers.ocppFeature,
          error: m.headers.ocppCause,
          message: body,
        };
        console.log(receivedMessage);

        if ("CONNECT" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          setTimeout(() => {
            EventBus.$emit("onConnect", {
              websocket: WEBSOCKET,
              message: receivedMessage,
            });
          }, 1000);
        } else if ("DISCONNECT" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onDisconnect", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        } else if ("SEND" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onSend", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        } else if ("RECEIVED" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onReceived", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        } else if ("ERROR" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onError", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        } else if ("HANDLE" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onHandle", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        } else if ("STATE" == m.headers.ocppOperation) {
          // console.log(m.headers.ocppOperation, receivedMessage);
          EventBus.$emit("onState", {
            websocket: WEBSOCKET,
            message: receivedMessage,
          });
        }
      },
      WEBSOCKET.headers()
    );
  },

  publish(targetUri, msg) {
    // /app/ocpp1.6/999bcbf7-f8b6-4ad4-b9dd-24d2e08948bd/999bcbf7-f8b6-4ad4-b9dd-24d2e08948bd
    const uri = publishUri + targetUri.target;
    const txt = msg instanceof Object ? JSON.stringify(msg) : msg;
    // stompClient.send(uri, WEBSOCKET.headers(), txt);
    stompClient.publish({
      destination: uri,
      body: txt,
      headers: WEBSOCKET.headers(),
    });
  },
};

export default WEBSOCKET;
