<template>
  <v-card>
    <!-- 원래는 v-if=standalone && 'create' === action으로 되어 있었음 -->
    <v-app-bar v-if="'create' === action" color="deep-purple accent-4" dense>
      <v-toolbar-title>새로운 충전소 생성하기 </v-toolbar-title>
    </v-app-bar>

    <!-- 원래는 v-if=standalone && 'update' === action으로 되어 있었음 -->
    <v-app-bar v-if="'update' === action" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [{{ management.name }}] - 기본 정보</v-toolbar-title
      >
    </v-app-bar>

    <!-- <v-card-subtitle>{{
      standalone ? "&nbsp;" : "기본 정보"
    }}</v-card-subtitle> -->
    <v-card-subtitle>&nbsp;</v-card-subtitle>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="management.name"
              :rules="rules.name"
              outlined
              label="충전 스테이션 명칭"
              :filled="!standalone"
              :readonly="!standalone"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="management.description"
              label="설명"
              outlined
              required
              :filled="!standalone"
              :readonly="!standalone"
            ></v-textarea>
          </v-col>

          <!-- <v-col>
            <v-chip
              style="float: right"
              small
              color="error"
              @click="managementsMetadataOpen('new', true)"
              >New</v-chip
            >
          </v-col> -->

          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      속성 이름
                    </th>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      Key
                    </th>
                    <th
                      style="border: 1px gray solid"
                      width="30%"
                      class="text-left"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in management.attributes" :key="k">
                    <td style="border: 1px gray solid">{{ v.name }}</td>
                    <td style="border: 1px gray solid">{{ v.key }}</td>
                    <td
                      v-if="v.key === 'cert_key' && !Authorization.isAdminId()"
                      style="border: 1px gray solid"
                      @click="managementsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      ***************
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="managementsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                    <td
                      v-if="v.key === 'cert_key' && Authorization.isAdminId()"
                      style="border: 1px gray solid"
                      @click="managementsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      {{ v.value }}{{ v.text }}
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="managementsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                    <td
                      v-if="v.key !== 'cert_key' && v.key !== 'snm'"
                      style="border: 1px gray solid"
                      @click="managementsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      {{ v.value }}{{ v.text }}
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="managementsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                    <td
                      v-if="v.key === 'snm'"
                      style="border: 1px gray solid"
                      @click="managementsMetadataOpen('edit', v)"
                      class="text-left"
                    >
                      {{ management.name }}
                      <!-- <v-chip
                        style="float: right"
                        x-small
                        @click="managementsMetadataOpen('remove', v)"
                        >X</v-chip
                      > -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-dialog v-model="managementsMetadata" width="40%" persistent>
          <managements-metadata
            ref="managementsMetadata"
            @inactive="managementsMetadataClose"
          />
        </v-dialog>
      </v-form>
    </v-card-text>
    <!-- 
    <v-divider ></v-divider>
      {{management}}
 -->
    <v-divider v-if="standalone"></v-divider>
    <!-- <v-card-actions v-if="standalone"> -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="'create' === action"
        color="error"
        text
        @click="createElement"
      >
        Create
      </v-btn>

      <v-btn
        v-if="'update' === action"
        color="error"
        text
        @click="updateElement"
      >
        Update
      </v-btn>

      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import OCPP_SOFTBERRY from "@/app/env.ocpp.softberry.js";

import ManagementsMetadata from "@/app/contents/managements/ManagementsMetadata.vue";
import Validator from "../../app.validator.js";
import Authorization from "../../env.authorization.js";

export default {
  name: "ManagementsMetadatas",

  props: {
    standalone: { type: Boolean },
  },

  components: {
    ManagementsMetadata,
  },

  data: () => ({
    Authorization: Authorization,
    action: null,
    management: {},
    initManagement: {},

    rules: {
      name: [Validator.required("충전스테이션의 이름이 필요합니다.")],
    },

    managementsMetadata: false,
    isEqual: 0,
  }),

  updated() {
    if (this.management.name) {
      this.management.attributes.snm.value = this.management.name;
    }
  },

  methods: {
    active(management, action) {
      console.log(this.$options.name, "active", action);

      this.action = action;
      this.management = management;
      // 깊은 복사로 아예 끊어버림
      this.initManagement = JSON.parse(JSON.stringify(management));

      if (this.management.attributes == undefined) {
        this.management.attributes = {};
      }

      const sbAttrs = OCPP_SOFTBERRY.managements.attrs(); // 이 안에는 정적인 값들만 존재

      // 운영기관 리스트(가변적인 데이터) API 로 받아와서 충전소 기본정보 안에 세팅
      OCPP_API.managements.initOperator(this.management).then((res) => {
        sbAttrs.operator.options = res.data.operator;
      });

      Object.keys(sbAttrs).forEach((k) => {
        if (this.management.attributes[k] == undefined) {
          this.$set(this.management.attributes, k, sbAttrs[k]);
        }
      });
    },

    inactive(args) {
      console.log(this.$options.name, "inactive", args);

      // 20개의 attributes가 전부 똑같을 때만 초기값을 덮어씌움
      // 처음 충전소 생성할 때에는 'value'가 없음
      if (this.action === "update") {
        for (const [key, value] of Object.entries(this.management.attributes)) {
          if (this.initManagement.attributes[key].value == value.value) {
            this.isEqual++;
            if (
              this.isEqual === Object.keys(this.management.attributes).length
            ) {
              console.log("초기값이랑 수정값이 같음");
              this.management.attributes = this.initManagement.attributes;
            }
          }
        }
      }

      this.management.name = this.initManagement.name;
      this.$emit("inactive", args);
    },

    updateElement() {
      if (!this.$refs.form.validate()) return;
      OCPP_SOFTBERRY.managements.valid(this.management, () => {
        OCPP_API.managements.update(this.management).then(() => {
          this.inactive(true);
          // window.location.reload();
        });
      });
    },

    createElement() {
      if (!this.$refs.form.validate()) return;
      OCPP_SOFTBERRY.managements.valid(this.management, () => {
        OCPP_API.managements.create(this.management).then(() => {
          this.inactive(true);
          // alert('add');
          //  SOFTBERRY.group().then(()=>{
          // });
        });
      });
    },

    managementsMetadataOpen(action, attr) {
      console.log(this.$options.name, "managementsMetadataOpen", action);
      if ("new" == action) {
        this.managementsMetadata = true;
        setTimeout(() => {
          this.$refs.managementsMetadata.active(
            {
              edit: false,
              name: null,
              key: null,
              value: null,
              options: null,
              description: null,
            },
            attr
          );
        }, 100);
      } else if ("edit" == action) {
        this.managementsMetadata = true;
        setTimeout(() => {
          this.$refs.managementsMetadata.active(attr, this.action);
        }, 100);
      } else if ("remove" == action) {
        this.$confirm({
          message: "삭제 하시겠습니까?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              this.$delete(this.management.attributes, attr.key);
            }
          },
        });
      }
    },

    managementsMetadataClose(returnValue) {
      console.log(this.$options.name, "managementsMetadataClose", returnValue);
      if (returnValue === false) {
        this.managementsMetadata = false;
      } else {
        this.managementsMetadata = false;
        this.$set(this.management.attributes, returnValue.key, returnValue);
      }
    },
  },
};
</script>

<style>
.text-left:hover {
  cursor: pointer;
  background-color: blue;
}
</style>
