<template>
  <v-card>
    <v-form ref="form">
      <v-app-bar color="deep-purple accent-4" dense>
        <v-toolbar-title
          >충전 설비 [ {{ equipment.management.name }} / {{ equipment.name }} ]
          - 펌웨어 업데이트</v-toolbar-title
        >
      </v-app-bar>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
              v-model="element"
              :items="elements"
              :rules="rules.firmware"
              :item-text="readElement"
              label="펌웨어 버전 선택 "
              outlined
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="standalone"></v-divider>
      <v-card-actions v-if="standalone">
        <v-spacer></v-spacer>

        <v-btn color="error" text @click="updateElement"> Update </v-btn>
        <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Authorization from "@/app/env.authorization.js";
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from "../../app.validator.js";

export default {
  name: "EquipmentsFirmware",

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    equipment: { management: {} },

    rules: {
      firmware: [Validator.required("펌웨어 파일이 필요합니다.")],
    },

    element: null,
    elements: [],
    elementSearchForm: {
      query: {},
    },
  }),

  methods: {
    active(equipment) {
      console.log(this.$options.name, "active", equipment);
      this.equipment = equipment;
      this.element = null;
      this.searchElements(true);
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    readElement(firmware) {
      return (
        firmware.version +
        "-" +
        firmware.filename +
        " (" +
        firmware.size +
        " Bytes)"
      );
    },

    searchElements(reset) {
      if (this.elementSearchForm.query === undefined) return;

      if (reset === true) {
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 100;
        this.elementSearchForm.sortBy = ["timestamp"];
        this.elementSearchForm.sortDesc = ["desc"];
        this.elementSearchForm.query = {
          management: this.equipment.management.id,
        };
      }
      console.log(this.$options.name, "searchElements", this.elementSearchForm);

      OCPP_API.firmwares.search(this.elementSearchForm).then((r) => {
        console.log(this.$options.name, "searchElements", r);

        // this.elements에 데이터 저장 => 화면에 그냥 뿌려주는 용도
        this.elements = r.data._embedded.firmwares;
      });
    },

    updateElement() {
      if (!this.$refs.form.validate()) return;

      this.$confirm({
        message:
          "펌웨어 업데이트 요청 메세지를 충전 설비에 전송 하시겠습니까? \n 전송된 메세지는 취소 할 수 없습니다.",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            console.log(this.$options.name, "updateElement", this.element);

            const datetime = this.$moment().format();
            const location = this.element.path;
            const target = this.equipment.uri;
            const vendorId = Authorization.getAuthorizationData().username;

            const message201 = [
              2,
              datetime,
              "UpdateFirmware",
              {
                requestId: 0,
                firmware: {
                  location: location,
                  signature: "",
                  signingCertificate: "",
                  retrieveDateTime: datetime,
                  installDateTime: datetime,
                  customData: { vendorId: vendorId },
                },
                retryInterval: 0,
                customData: { vendorId: vendorId },
                retries: 0,
              },
            ];

            const message16 = [
              2,
              datetime,
              "UpdateFirmware",
              {
                location: location,
                retrieveDate: datetime,
                retryInterval: 0,
                retries: 0,
              },
            ];

            let message = "";
            if (target.substr(1, target.substr(1).indexOf("/")) === "ocpp1.6") {
              message = message16;
            } else {
              message = message201;
            }

            const returnValue = {
              target: { target: target },
              message: message,
            };

            console.log(this.$options.name, "updateElement", returnValue);

            this.inactive(returnValue);
          }
        },
      });
    },
  },
};
</script>

<style></style>
