import Authorization from "@/app/env.authorization.js";
import axios from "axios";
// import qs from "qs";

const uri = process.env.VUE_APP_MULTIPART_SERVER;

const Multipart = {
  headers(h) {
    return Authorization.getAuthorizationHeader(h);
  },

  multiparts: {
    upload(file) {
      let formData = new FormData();
      formData.append("file", file);
      // if(filename != undefined){
      //     formData.append('filename', filename);
      // }

      return axios({
        headers: Multipart.headers({ "Content-Type": "multipart/form-data" }),
        url: `${uri}/multipart`,
        method: "POST",
        data: formData,
      });
    },

    download(id, filename) {
      return axios({
        headers: Multipart.headers(),
        url: id,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });

        //MSIE
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(blob, filename);
          return;
        }

        const blobURL = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL; // or href;
        a.download = filename; // or force filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(blobURL); // or href
      });
    },
    delete(id) {
      return axios({
        headers: Multipart.headers(),
        url: id,
        method: "DELETE",
      });
    },
  },
};

export default Multipart;
