<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [ {{ management.name }} ] - 과금 정책
        관리</v-toolbar-title
      >
    </v-app-bar>

    <v-card-subtitle>{{
      standalone ? "&nbsp;" : " 과금 프로파일"
    }}</v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options="elementSearchForm"
        @update:options="(val) => (elementSearchForm = val)"
        multi-sort
        class="elevation-24"
        dense
      >
        <template v-slot:top>
          <v-row>
            <v-col clos="12" align="right">
              <v-dialog
                v-if="standalone"
                v-model="managementsPrice"
                width="80%"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    class="mb-2"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      managementsPriceOpen({ management: management.id }, true)
                    "
                  >
                    NEW
                  </v-btn>
                </template>

                <v-card>
                  <v-form ref="form">
                    <managements-price
                      ref="managementsPrice"
                      :standalone="true"
                      @inactive="managementsPriceClose"
                    />
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <a @click="managementsPriceOpen(item, false)">{{ item.name }}</a>
        </template>

        <template v-slot:[`item.deleted`]="{ item }">
          {{ item.deleted ? "비활성화" : "활성화" }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div v-if="standalone" class="text-sm-caption">
            <v-btn
              dense
              color="error"
              text
              @click="
                removeElement(
                  item,
                  item.occupied
                    ? item.deleted
                      ? 'active'
                      : 'inactive'
                    : 'remove'
                )
              "
              x-small
            >
              {{
                item.occupied
                  ? item.deleted
                    ? "active"
                    : "inactive"
                  : "remove"
              }}
            </v-btn>
            <!-- 
            <v-btn
              dense
              color="error"
              text
              @click="removeElement(item, 'remove')"
              x-small
            >
              remove2
            </v-btn> -->
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>

      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";

import ManagementsPrice from "@/app/contents/managements/ManagementsPrice.vue";

import Validator from "../../app.validator.js";

export default {
  name: "ManagementsPrices",

  props: {
    standalone: { type: Boolean },
  },

  components: {
    ManagementsPrice,
  },

  data: () => ({
    management: {},

    rules: {
      version: [Validator.required("속성 이름이 필요합니다.")],
      file: [Validator.required("속성 값이 필요합니다.")],
    },

    managementsPrice: false,
    element: {},
    elements: [],

    elementSearch: {
      headers: [
        { text: "명칭", value: "name" },
        { text: "시작일", value: "startDay" },
        { text: "반복", value: "cronComment" },
        { text: "종료일", value: "endDay" },
        // { text: "24시간 요금표", value: "prices" },
        { text: "사용 이력", value: "occupied" },
        { text: "사용 여부", value: "deleted" },
        { text: "action", value: "action", sortable: false },
      ],
      totalElements: 0,
      loading: true,
    },
    elementSearchForm: {
      query: {},
    },
  }),

  // 검색조건이 바뀌면 즉시 api통해 데이터를 다시 불러와야하기 때문에 watch를 사용
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //즉시 변경시...
    },
  },

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.management = args;
      this.searchElements(true); // 해당 충전소의 과금만 불러오기 위한 소스
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    searchElements(reset) {
      if (
        this.elementSearchForm.query === undefined ||
        typeof reset !== "boolean"
      )
        return;
      this.elementDialog = false;

      if (reset === true) {
        // this.elementSearchForm.query = this.query;
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 5;
        this.elementSearchForm.sortBy = ["timestamp"];
        this.elementSearchForm.sortDesc = ["desc"];
        this.elementSearchForm.query = { management: this.management.id };
      }
      console.log(this.$options.name, "searchElements", this.elementSearchForm);

      OCPP_API.prices.search(this.elementSearchForm).then((r) => {
        console.log(this.$options.name, "searchElements", r);

        this.elements = r.data._embedded.prices;
        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
      });
    },

    managementsPriceOpen(args, isNew) {
      this.managementsPrice = true;
      setTimeout(() => {
        this.$refs.managementsPrice.active(args, isNew);
      }, 100);
    },
    managementsPriceClose(args) {
      this.managementsPrice = false;
      if (true === args) {
        this.searchElements(args);
      }
    },

    removeElement(element, type) {
      if ("active" === type) {
        this.$confirm({
          message: "과금 정책을 활성화 하시겠습니까?",
          button: { no: "No", yes: "Yes" },
          callback: (confirm) => {
            if (confirm) {
              console.log(this.$options.name, "active", element);
              element.deleted = false;
              OCPP_API.prices.update(element).then((r) => {
                console.log(this.$options.name, "removeElement2", r);
                this.searchElements(true);
              });
            }
          },
        });
      } else if ("inactive" === type) {
        this.$confirm({
          message: "과금 정책을 비활성화 하시겠습니까?",
          button: { no: "No", yes: "Yes" },
          callback: (confirm) => {
            if (confirm) {
              console.log(this.$options.name, "active", element);
              element.deleted = true;
              OCPP_API.prices.update(element).then((r) => {
                console.log(this.$options.name, "removeElement2", r);
                this.searchElements(true);
              });
            }
          },
        });
      } else if ("remove" === type) {
        this.$confirm({
          message: "과금 정책을 삭제 하시겠습니까?",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              console.log(this.$options.name, "removeElement1", element);
              OCPP_API.prices.delete(element).then((r) => {
                console.log(this.$options.name, "removeElement2", r);
                this.searchElements(true);
              });
            }
          },
        });
      }
    },
  },
};
</script>

<style></style>
