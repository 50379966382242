<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [{{ management.name }}] - 공유 옵션
      </v-toolbar-title>
    </v-app-bar>

    <v-card-subtitle>{{ standalone ? "&nbsp;" : "공유 옵션" }}</v-card-subtitle>

    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="border: 1px gray solid" width="40%" class="text-left">
                Email
              </th>
              <th style="border: 1px gray solid" width="50%" class="text-left">
                공유 상태
              </th>
              <th
                v-if="owner && standalone"
                style="border: 1px gray solid"
                width="50%"
                class="text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-for="(v, k) in managers" v-bind:key="k">
            <tr>
              <td style="border: 1px gray solid">
                <!-- 공유 옵션에서 지금 로그인한 계정에 'You' 주기 위함 -->
                {{
                  v.email === getEmail()
                    ? "You"
                    : v.email === null
                    ? "Owner"
                    : v.email
                }}
              </td>
              <td style="border: 1px gray solid">
                <v-chip v-if="v.owner" dense x-small color="orange"
                  >Owner</v-chip
                >
                <v-chip v-if="!v.owner && v.active" color="orange" dense x-small
                  >Active</v-chip
                >
                <v-chip
                  v-if="!v.owner && !v.active"
                  color="orange"
                  dense
                  x-small
                  outlined
                  >Inactive</v-chip
                >
              </td>
              <td
                v-if="owner && standalone"
                style="border: 1px gray solid"
                class="text-center"
              >
                <v-btn
                  v-if="!v.owner"
                  color="error"
                  text
                  x-small
                  @click="removeElement(v)"
                  >Remove</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-if="owner && standalone">
      <v-form ref="form">
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="elementEditForm.email"
              :rules="elementEdit.rules.email"
              dense
              outlined
              placeholder="공유할 유저의 이메일을 입력해 주세요 "
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn text color="error" @click="createElement">추가 </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>

      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Authorization from "@/app/env.authorization.js";
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from "../../app.validator.js";

export default {
  name: "ManagementsShare",

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    elementEditForm: {
      management: null,
      email: null,
    },

    elementEdit: {
      rules: {
        email: [
          Validator.required("이메일 주소가 필요합니다."),
          Validator.email("이메일 형식에 맞지 않습니다."),
        ],
      },
    },

    management: {},
    managers: [],
    owner: false,
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.management = args;
      // 충전소를 만든 사람이랑 === 지금 로컬스토리지에 저장된 사람이랑 같은지 판별해서 owner에 저장
      this.owner =
        this.management._created.username ===
        Authorization.getAuthorizationData().username;
      console.log(this.owner); // false
      this.elementEditForm.management = this.management.id;

      this.searchElements();
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    // 공유 옵션에서 지금 로그인한 계정에 'You' 주기 위함
    getEmail() {
      const emailKey = process.env.VUE_APP_AUTHORIZATION_STORAGE_EMAIL;
      return localStorage.getItem(emailKey);
    },

    searchElements() {
      const query = { query: { management: this.management.id } };
      console.log(this.$options.name, "searchElements", query);
      OCPP_API.managers.search(query).then((r) => {
        this.managers = r.data._embedded.managers;
        console.log(this.managers); // 여기서 username도 같이 받아올 수 있나? username이 null인 값은 뭐지?
      });
    },

    createElement() {
      if (!this.$refs.form.validate()) return;

      console.log(this.$options.name, "createElement");
      OCPP_API.managers.create(this.elementEditForm).then(() => {
        this.elementEditForm.email = null;
        this.searchElements();
      });
    },
    removeElement(manager) {
      console.log(this.$options.name, "removeElement");

      this.$confirm({
        message:
          "삭제 하시겠습니까? 삭제된 유저는 충전소 정보에 접근할 수 없습니다.",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            OCPP_API.managers.delete(manager).then(() => {
              this.searchElements();
            });
          }
        },
      });
    },
  },
};
</script>

<style></style>
