<template>
  <v-card>
    <v-form ref="form">
      <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
        <v-toolbar-title
          >충전 설비 [ {{ equipment.management.name }} / {{ equipment.name }} ]
          - 과금 정책 설정
        </v-toolbar-title>
      </v-app-bar>

      <v-card-subtitle>{{
        standalone ? "&nbsp;" : " 과금 프로파일"
      }}</v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="equipment.prices"
                :items="elements"
                :rules="rules.prices"
                item-text="priceName"
                outlined
                chips
                small-chips
                deletable-chips
                label="과금 정책"
                multiple
                persistent-hint
                return-object
              ></v-autocomplete>
              <p v-if="equipment.prices">
                충전 과금은 "{{ equipment.prices[0].priceName }}" 우선으로
                검토되어 결정됩니다.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider v-if="standalone"></v-divider>
      <v-card-actions v-if="standalone">
        <v-spacer></v-spacer>

        <v-btn color="error" text @click="updateElement"> Update </v-btn>

        <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from "../../app.validator.js";

export default {
  name: "EquipmentsPrice",

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    equipment: { management: {} },
    initEquipment: { management: {} },

    rules: {
      prices: [Validator.required("속성 값이 필요합니다.")],
    },

    elements: [],
    elementSearchForm: {
      query: {},
    },
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.equipment = args;
      this.initEquipment = { ...args };
      this.searchElement();
    },

    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.equipment.prices = this.initEquipment.prices;
      this.$emit("inactive", args);
    },

    searchElement() {
      this.elementSearchForm.page = 1;
      this.elementSearchForm.itemsPerPage = 100000;
      this.elementSearchForm.query = {
        management: this.equipment.management.id,
        deleted: false,
      };

      console.log(this.$options.name, "searchElement", this.elementSearchForm);
      // 충전기의 해당 충전소의 과금정책을 다 불러와서
      OCPP_API.prices.search(this.elementSearchForm).then((r) => {
        console.log(this.$options.name, "searchElement", r);
        this.elements = [];

        // this.elements에 과금정책id랑 과금정책이름을 push => 화면에 그냥 뿌려주는 용도
        r.data._embedded.prices.forEach((p) => {
          this.elements.push({ priceId: p.id, priceName: p.name });
        });
      });
    },

    updateElement() {
      // 과금을 설정 안 할 수도 있나? 과금을 선택 안 할 수도 있다면, validate 필요 x
      // if (!this.$refs.form.validate()) return;

      console.log(this.$options.name, "updateElement", this.equipment); // 여기서 찍으면 콘솔에 휘리릭 지나가서 볼수없음
      console.log(this.$options.name, "updateElement", this.equipment.prices); // 바인딩이 되어있겠지

      OCPP_API.equipments.update(this.equipment).then(() => {
        this.inactive(true);
      });
    },
  },
};
</script>

<style></style>
