<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [{{ management.name }}] - OCPP
      </v-toolbar-title>
    </v-app-bar>

    <v-card-subtitle>{{ standalone ? "&nbsp;" : "OCPP" }}</v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="OCPP URL"
            v-for="u in management.uri"
            :key="u"
            :value="ws + '' + u"
            no-resize
            filled
            rows="2"
            outlined
            readonly
          ></v-text-field>

          <v-textarea
            label="OCPP Token"
            rows="4"
            v-model="token"
            no-resize
            filled
            outlined
            readonly
            hint="위 접속 주소와 토큰을 충전 설비 제조사 에게 전달 하여 주십시오. * 는 충전 설비를 지칭하는 유니크한 아이디 입니다. 자유롭게 정의 할 수 있으나 중복 되어서는 안됩니다. 접속 토큰은 보안에 유의하여야 합니다. "
            persistent-hint
          ></v-textarea>
        </v-col>

        <!-- <v-dialog v-model="equipmentsMetadatas" width="80%" persistent>
          <equipments-metadatas
            ref="equipmentsMetadatas"
            :standalone="true"
            @inactive="equipmentsMetadatasClose"
          />
        </v-dialog> -->
      </v-row>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <!--
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="success"
          text 
          @click="popup('../mockup-server')"
        >
          Monitor
        </v-btn>
      </template>
      <span>해당 충전 스테이션의 OCPP 메세지를 실시간 모니터링 합니다.<br/> 팦업을 허용하여야 합니다.</span>
    </v-tooltip> @click="popup('../mockup-client')"
-->

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            text
            @click="popup('../mockup-client')"
          >
            EVSE-Mock
          </v-btn>
        </template>
        <span
          >가상의 OCPP 충전 설비를 mockup 하여 실시간 테스트 할 수 있습니다.<br />
          팦업을 허용하여야 합니다.</span
        >
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Authorization from "@/app/env.authorization.js";

const ws = process.env.VUE_APP_OCPP_WS_SERVER;

export default {
  name: "ManagementsConnection",
  component: {},

  props: {
    standalone: { type: Boolean },
  },

  data: () => ({
    ws: ws,
    token: Authorization.getAuthorizationData().token,
    management: {},
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
      this.management = args;
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    popup(path) {
      console.log(this.$options.name, "popup", path);
      this.$emit("input", false);

      let uri = path;
      uri += "?host=" + this.ws;
      uri += "&group=" + this.management.id;
      uri += "&token=" + this.token;

      window.open(
        uri,
        path,
        "toolbar=no,resizable=yes,top=0,left=300,width=800,height=600"
      );
      this.inactive(false);
    },
  },
};
</script>

<style></style>
