var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.standalone)?_c('v-app-bar',{attrs:{"color":"deep-purple accent-4","dense":""}},[_c('v-toolbar-title',[_vm._v("충전 스테이션 ["+_vm._s(_vm.management.name)+"] - 펌웨어 관리")])],1):_vm._e(),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.standalone ? " " : "펌웨어 관리"))]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-24",attrs:{"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"items":_vm.elements,"loading":_vm.elementSearch.loading,"headers":_vm.elementSearch.headers,"server-items-length":_vm.elementSearch.totalElements,"options":_vm.elementSearchForm,"multi-sort":"","dense":""},on:{"update:options":function (val) { return (_vm.elementSearchForm = val); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"clos":"12","align":"right"}},[(_vm.standalone)?_c('v-dialog',{attrs:{"width":"40%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.managementsFirmwareOpen(
                      { management: _vm.management.id },
                      _vm.latestVersion
                    )}}},'v-btn',attrs,false),on),[_vm._v(" NEW ")])]}}],null,false,2701401061),model:{value:(_vm.managementsFirmware),callback:function ($$v) {_vm.managementsFirmware=$$v},expression:"managementsFirmware"}},[_c('managements-firmware',{ref:"managementsFirmware",attrs:{"standalone":true},on:{"inactive":_vm.managementsFirmwareClose}})],1):_vm._e()],1)],1)]},proxy:true},{key:"item.timestamp",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-sm-caption"},[_vm._v(" "+_vm._s(_vm.$moment(item._created.timestamp).format("YYYY-MM-DD HH:mm:ss"))+" ")])]}},{key:"item.file",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-sm-caption"},[_c('a',{on:{"click":function($event){return _vm.downloadElement(item)}}},[_vm._v(" "+_vm._s(item.filename)+" ("+_vm._s(item.size)+"Bytes) ")])])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(_vm.standalone)?_c('div',{staticClass:"text-sm-caption"},[_c('v-btn',{attrs:{"dense":"","color":"error","text":"","x-small":""},on:{"click":function($event){return _vm.removeElement(item)}}},[_vm._v(" REMOVE ")])],1):_vm._e()]}}],null,true)})],1),(_vm.standalone)?_c('v-divider'):_vm._e(),(_vm.standalone)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.inactive(false)}}},[_vm._v(" Close ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }