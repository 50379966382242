<template>
  <v-card>
    <v-form ref="form">
      <v-app-bar color="deep-purple accent-4" dense>
        <v-toolbar-title>펌웨어 파일 등록</v-toolbar-title>
      </v-app-bar>
      <v-card-subtitle>&nbsp;</v-card-subtitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="element.version"
              :rules="version"
              outlined
              label="버전"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="element.file"
              :rules="rules.file"
              outlined
              label="파일 업로드 "
              prepend-icon=""
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="createElement"> Create </v-btn>
        <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
      </v-card-actions>
    </v-form>

    <v-dialog v-model="progressDialog" width="30%" persistent>
      <v-progress-circular
        indeterminate
        color="deep-purple accent-4"
      ></v-progress-circular>
    </v-dialog>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import MULTIPART from "@/app/env.multipart.js";
import Validator from "../../app.validator.js";

export default {
  name: "ManagementsFirmware",

  props: {
    standalone: { type: Boolean },
  },

  // data에서 latestVersion을 사용하기 위해 computed를 사용함
  computed: {
    version() {
      return [
        Validator.required("속성 값이 필요합니다."),
        Validator.firmwareVersion(
          "가장 최근 펌웨어 버전보다 높아야 합니다.",
          this.latestVersion
        ),
      ];
    },
  },

  data() {
    return {
      rules: {
        file: [Validator.required("속성 값이 필요합니다.")],
      },

      latestVersion: 0,
      progressDialog: false,
      element: {},
    };
  },

  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //검색어 즉시 변경시...
    },
  },

  methods: {
    active(element, latestVersion) {
      console.log(this.$options.name, "active", element, latestVersion);
      this.element = element;
      this.latestVersion = latestVersion;
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    createElement() {
      if (!this.$refs.form.validate()) return;

      this.progressDialog = true;

      MULTIPART.multiparts.upload(this.element.file).then((r) => {
        console.log(this.$options.name, "createElement1", r);

        this.element.size = r.data.size;
        this.element.filename = r.data.filename;
        this.element.path = r.data._links.self.href;

        console.log(this.$options.name, "createElement2", this.element);

        OCPP_API.firmwares.create(this.element).then((r) => {
          console.log(this.$options.name, "createElement3", r);
          this.progressDialog = false;
          this.inactive(true);
        });
      });
    },
  },
};
</script>

<style></style>
