<template>
  <v-card>
    <v-app-bar v-if="standalone" color="deep-purple accent-4" dense>
      <v-toolbar-title
        >충전 스테이션 [{{ management.name }}] - 펌웨어 관리</v-toolbar-title
      >
    </v-app-bar>

    <v-card-subtitle>{{
      standalone ? "&nbsp;" : "펌웨어 관리"
    }}</v-card-subtitle>

    <v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        :items="elements"
        :loading="elementSearch.loading"
        :headers="elementSearch.headers"
        :server-items-length="elementSearch.totalElements"
        :options="elementSearchForm"
        @update:options="(val) => (elementSearchForm = val)"
        multi-sort
        class="elevation-24"
        dense
      >
        <template v-slot:top>
          <v-row>
            <v-col clos="12" align="right">
              <v-dialog
                v-if="standalone"
                v-model="managementsFirmware"
                width="40%"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    class="mb-2"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      managementsFirmwareOpen(
                        { management: management.id },
                        latestVersion
                      )
                    "
                  >
                    NEW
                  </v-btn>
                </template>
                <managements-firmware
                  ref="managementsFirmware"
                  :standalone="true"
                  @inactive="managementsFirmwareClose"
                />
              </v-dialog>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.timestamp`]="{ item }">
          <div class="text-sm-caption">
            {{ $moment(item._created.timestamp).format("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </template>

        <template v-slot:[`item.file`]="{ item }">
          <div class="text-sm-caption">
            <a @click="downloadElement(item)">
              {{ item.filename }} ({{ item.size }}Bytes)
            </a>
          </div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div v-if="standalone" class="text-sm-caption">
            <v-btn
              dense
              color="error"
              text
              @click="removeElement(item)"
              x-small
            >
              REMOVE
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider v-if="standalone"></v-divider>
    <v-card-actions v-if="standalone">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import MULTIPART from "@/app/env.multipart.js";
import Validator from "../../app.validator.js";
import ManagementsFirmware from "@/app/contents/managements/ManagementsFirmware.vue";

export default {
  name: "ManagementsFirmwares",

  props: {
    standalone: { type: Boolean },
  },

  components: {
    ManagementsFirmware,
  },

  data: () => ({
    management: {},

    rules: {
      version: [Validator.required("속성 값이 필요합니다.")],
      file: [Validator.required("속성 값이 필요합니다.")],
    },

    managementsFirmware: false,
    element: {},
    elements: [],
    latestVersion: 0,

    elementSearch: {
      headers: [
        {
          text: "timestamp",
          align: "start",
          value: "timestamp",
        },
        { text: "version", value: "version", sortable: false },
        { text: "file", value: "file", sortable: false },
        { text: "action", value: "action", sortable: false },
        // { text: "attributes", value: "attributes" },
      ],
      totalElements: 0,
      loading: true,
    },
    elementSearchForm: {
      query: {},
    },
  }),

  // 검색조건이 바뀌면 즉시 api통해 데이터를 다시 불러와야하기 때문에 watch를 사용
  watch: {
    elementSearchForm: {
      handler: "searchElements",
      // deep: true, //검색어 즉시 변경시...
    },
  },

  methods: {
    /**
     * active
     * @param management
     */
    active(management) {
      console.log(this.$options.name, "active", management);
      this.management = management;
      this.searchElements(true); // 해당 충전소의 펌웨어만 가져오기 위함
    },

    /**
     * inactive
     * @param args
     */
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    /**
     * search
     * @param reset
     */
    searchElements(reset) {
      if (
        this.elementSearchForm.query === undefined ||
        typeof reset !== "boolean"
      )
        return; // reset이 this.elementSearchForm으로 넘어오는 경우 아래 로직 타면 안됨
      this.elementDialog = false;

      if (reset === true) {
        // this.elementSearchForm.query = this.query;
        this.elementSearchForm.page = 1;
        this.elementSearchForm.itemsPerPage = 5;
        this.elementSearchForm.sortBy = ["timestamp"];
        this.elementSearchForm.sortDesc = ["desc"];
        this.elementSearchForm.query = { management: this.management.id };
      }
      console.log(this.$options.name, "searchElements", this.elementSearchForm);

      OCPP_API.firmwares.search(this.elementSearchForm).then((r) => {
        console.log(this.$options.name, "searchElements", r);

        this.elements = r.data._embedded.firmwares;
        if (r.data._embedded.firmwares.length > 0) {
          // firmwares가 한 개 이상은 있어야 됨
          this.latestVersion = Number.parseFloat(
            this.elements[this.elements.length - 1].version
          );
        }
        this.elementSearch.totalElements = r.data.page.totalElements;
        this.elementSearch.loading = false;
      });
    },

    /**
     * remove
     * @param element
     */
    removeElement(element) {
      this.$confirm({
        message: "삭제 하시겠습니까?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            console.log(this.$options.name, "removeElement1", element);

            OCPP_API.firmwares.delete(element.id).then((r) => {
              console.log(this.$options.name, "removeElement2", r);
              this.searchElements(true);
              MULTIPART.multiparts.delete(element.path).then((x) => {
                console.log(this.$options.name, "removeElement3", x);
              });
            });
          }
        },
      });
    },

    /**
     * download
     * @param element
     */
    downloadElement(element) {
      MULTIPART.multiparts.download(element.path, element.filename);
    },

    /**
     * upload
     * @param args
     * @param latestVersion
     */
    managementsFirmwareOpen(args, latestVersion) {
      this.managementsFirmware = true;
      setTimeout(() => {
        this.$refs.managementsFirmware.active(args, latestVersion);
      }, 100);
    },

    /**
     * close
     * @param args
     */
    managementsFirmwareClose(args) {
      this.managementsFirmware = false;
      if (true === args) {
        this.searchElements(args);
      }
    },
  },
};
</script>

<style></style>
