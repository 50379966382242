<template>
  <v-card>
    <v-form ref="form">
      <v-app-bar color="deep-purple accent-4" dense>
        <v-toolbar-title>충전 스테이션 공유 받기 </v-toolbar-title>
      </v-app-bar>

      <v-card-subtitle>&nbsp;</v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="element.code"
                :rules="rules.code"
                placeholder="수신된 메일에서 공유 코드가 필요합니다."
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error" text @click="updateElement"> 공유 받기 </v-btn>

        <v-btn color="primary" text @click="inactive(false)"> Close </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import OCPP_API from "@/app/env.ocpp.api.js";
import Validator from "../../app.validator.js";

export default {
  name: "ManagementsShared",

  data: () => ({
    element: {},
    rules: {
      code: [Validator.required("공유 코드가 필요합니다.")],
    },
  }),

  methods: {
    active(args) {
      console.log(this.$options.name, "active", args);
    },
    inactive(args) {
      console.log(this.$options.name, "inactive", args);
      this.$emit("inactive", args);
    },

    updateElement() {
      if (!this.$refs.form.validate()) return;

      OCPP_API.managers
        .update(this.element.code)
        .then(() => {
          this.inactive("input", {});
        })
        .catch(() => {
          this.$confirm({
            title: "공유 받기",
            message: "공유 코드를 찾을 수 없습니다. ",
            button: {
              yes: "OK",
            },
            callback: () => {},
          });
        });
    },
  },
};
</script>

<style></style>
