<template>
  <v-row>
    <!-- :cols="Utils.cardWidth()" -->
    <!-- <v-col v-if="Utils.cardHead() == 'true' && managements.length > 0">
      <v-card :height="Utils.cardHeight()" elevation="24">
        <v-app-bar color="deep-purple accent-4" dense dark>
          <v-toolbar-title
            >충전 통계
            <span class="text-caption">
              {{
                Utils.chartRange() == "0"
                  ? "[" + $moment().format("YYYY-MM-DD") + "]"
                  : ""
              }}
              {{
                Utils.chartRange() == "1"
                  ? "[" +
                    $moment().startOf("week").format("YYYY-MM-DD") +
                    "~" +
                    $moment().endOf("week").format("YYYY-MM-DD") +
                    "]"
                  : ""
              }}
              {{
                Utils.chartRange() == "2"
                  ? "[" +
                    $moment().startOf("month").format("YYYY-MM-DD") +
                    "~" +
                    $moment().endOf("month").format("YYYY-MM-DD") +
                    "]"
                  : ""
              }}
            </span>
          </v-toolbar-title> -->

          <!-- <v-spacer></v-spacer> -->

          <!-- <v-menu offset-x close-on-content-click light>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon plain v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="
                      Utils.chartRange(0);
                      dashboard(() => {});
                    "
                  >
                    Today
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="
                      Utils.chartRange(1);
                      dashboard(() => {});
                    "
                  >
                    Week
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="
                      Utils.chartRange(2);
                      dashboard(() => {});
                    "
                  >
                    Month
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-card-text>
          <StatisticsChart
            :width="300"
            :height="160 * (Utils.cardHeight() / 400)"
            ref="statisticsChart"
            :chart-data="managementKwhsCollection"
            :options="managementKwhsOption"
          ></StatisticsChart>
          <StatisticsChart
            :width="300"
            :height="160 * (Utils.cardHeight() / 400)"
            ref="statisticsChart"
            :chart-data="managementPricesCollection"
            :options="managementPricesOption"
          ></StatisticsChart>
        </v-card-text>
      </v-card>
    </v-col> -->

    <v-col :cols="Utils.cardWidth()" v-if="Utils.cardTail() == 'true'">
      <v-card :height="Utils.cardHeight()">
        <v-app-bar color="deep-purple accent-4" dense dark>
          <v-toolbar-title>충전소 관리</v-toolbar-title>
        </v-app-bar>
        <v-card-title align="center"> &nbsp; </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="Authorization.isAdmin()" cols="12" align="center">
              <a @click="
                managementsMetadatasOpen(
                  { name: null, description: null, attributes: {} },
                  'create'
                )
                ">새로운 충전 스테이션 생성하기
              </a>
            </v-col>
            <v-col v-if="Authorization.isAdmin()" cols="12" align="center">
              또는
            </v-col>
            <v-col cols="12" align="center">
              <a @click="managementsSharedOpen()">충전 스테이션 공유 받기 </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-for="(management, key) in managements"
      :key="key"
      :cols="Utils.cardWidth()"
    >
      <v-card :height="Utils.cardHeight(management.equipments.length >= 10 ? (management.equipments.length * 36)+80 : undefined)" elevation="24">
        <v-app-bar color="deep-purple accent-4" dense dark>
          <v-toolbar-title>충전소 [{{ management.name }}]</v-toolbar-title>

          <!-- <v-spacer></v-spacer> -->

          <v-menu offset-x close-on-content-click light>
            <template v-slot:activator="{ on }">
              <v-btn icon plain v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="managementsConnectionOpen(management)"
                  >
                    OCPP
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="managementsMetadatasOpen(management, 'update')"
                  >
                    기본 정보
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="managementsFirmwaresOpen(management)"
                  >
                    펌웨어 관리
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text block @click="managementsPricesOpen(management)">
                    과금정책 관리
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    block
                    @click="managementsShareOpen(management, 'update')"
                  >
                    공유 옵션
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-card-text>
          <v-row v-if="management.equipments.length == 0">
            <v-col cols="12">
              연결된 충전 설비가 없습니다.
              <a @click="managementsConnectionOpen(management)"> 연결 안내</a>
            </v-col>
          </v-row>

          <v-row
            v-for="equipment in orderByCid(management.equipments)"
            :key="equipment.id"
            no-gutters
          >
            <v-col cols="12">
              <v-menu offset-x close-on-content-click light>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon plain v-bind="attrs" v-on="on" :dark="false">
                    <v-icon left :color="equipment.state" large
                      >mdi-ev-station</v-icon
                    >
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        text
                        block
                        @click="equipmentsConnectionOpen(equipment)"
                      >
                        OCPP
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        text
                        block
                        @click="equipmentsMetadatasOpen(equipment)"
                      >
                        기본 정보
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        text
                        block
                        @click="equipmentsFirmwareOpen(equipment)"
                      >
                        펌웨어 업데이트
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn text block @click="equipmentsPriceOpen(equipment)">
                        과금정책 설정
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-chip class="ma-1" label x-small>{{ equipment.name }}</v-chip>
              <v-chip class="ma-1" label x-small
                >{{ equipment.firmware }}
              </v-chip>
              <v-chip class="ma-1" :color="equipment.status" label x-small>{{
                equipment.statusMsg
              }}</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="managementsConnection" width="80%">
      <managements-connection
        ref="managementsConnection"
        :standalone="true"
        @inactive="managementsConnectionClose"
      />
    </v-dialog>

    <v-dialog v-model="managementsMetadatas" width="80%">
      <managements-metadatas
        ref="managementsMetadatas"
        :standalone="true"
        @inactive="managementsMetadatasClose"
      />
    </v-dialog>

    <v-dialog v-model="managementsShare" width="80%">
      <managements-share
        ref="managementsShare"
        :standalone="true"
        @inactive="managementsShareClose"
      />
    </v-dialog>

    <v-dialog v-model="managementsShared" width="80%">
      <managements-shared @inactive="managementsSharedClose" />
    </v-dialog>

    <v-dialog v-model="managementsFirmwares" width="80%">
      <managements-firmwares
        ref="managementsFirmwares"
        :standalone="true"
        @inactive="managementsFirmwaresClose"
      />
    </v-dialog>

    <v-dialog v-model="managementsPrices" width="80%">
      <managements-prices
        ref="managementsPrices"
        :standalone="true"
        @inactive="managementsPricesClose"
      />
    </v-dialog>

    <v-dialog v-model="equipmentsConnection" width="80%">
      <equipments-connection
        ref="equipmentsConnection"
        :standalone="true"
        @inactive="equipmentsConnectionClose"
      />
    </v-dialog>

    <v-dialog v-model="equipmentsMetadatas" width="80%">
      <equipments-metadatas
        ref="equipmentsMetadatas"
        :standalone="true"
        @inactive="equipmentsMetadatasClose"
      />
    </v-dialog>

    <v-dialog v-model="equipmentsPrice" width="80%">
      <equipments-price
        ref="equipmentsPrice"
        :standalone="true"
        @inactive="equipmentsPriceClose"
      />
    </v-dialog>

    <v-dialog v-model="equipmentsFirmware" width="80%">
      <equipments-firmware
        ref="equipmentsFirmware"
        :standalone="true"
        @inactive="equipmentsFirmwareClose"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import Authorization from "@/app/env.authorization.js";
import OCPP_WS from "@/app/env.ocpp.ws.js";
import OCPP_API from "@/app/env.ocpp.api.js";
import Utils from "@/app/app.utils.js";

import ManagementsConnection from "@/app/contents/managements/ManagementsConnection.vue";
import ManagementsMetadatas from "@/app/contents/managements/ManagementsMetadatas.vue";
import ManagementsShare from "@/app/contents/managements/ManagementsShare.vue";
import ManagementsShared from "@/app/contents/managements/ManagementsShared.vue";
import ManagementsFirmwares from "@/app/contents/managements/ManagementsFirmwares.vue";
import ManagementsPrices from "@/app/contents/managements/ManagementsPrices.vue";

import EquipmentsConnection from "@/app/contents/equipments/EquipmentsConnection.vue";
import EquipmentsMetadatas from "@/app/contents/equipments/EquipmentsMetadatas.vue";
import EquipmentsFirmware from "@/app/contents/equipments/EquipmentsFirmware.vue";
import EquipmentsPrice from "@/app/contents/equipments/EquipmentsPrice.vue";

// import StatisticsChart from "@/app/contents/statistics/StatisticsChart.vue";

export default {
  components: {
    ManagementsConnection,
    ManagementsMetadatas,
    ManagementsShare,
    ManagementsShared,
    ManagementsFirmwares,
    ManagementsPrices,

    EquipmentsConnection,
    EquipmentsMetadatas,
    EquipmentsFirmware,
    EquipmentsPrice,
    // StatisticsChart,
  },
  data: () => ({
    Authorization: Authorization,
    Utils: Utils,

    managements: [],
    management: {},
    managementsConnection: false,
    managementsMetadatas: false,
    managementsShare: false,
    managementsShared: false,
    managementsFirmwares: false,
    managementsPrices: false,

    equipment: {},
    equipmentsConnection: false,
    equipmentsMetadatas: false,
    equipmentsFirmware: false,
    equipmentsPrice: false,

    // managementKwhsCollection: {},
    // managementKwhsOption: {},
    // managementPricesCollection: {},
    // managementPricesOption: {},

    managers: [],
  }),

  methods: {
    // 충전기 cid순 정렬
    orderByCid(equipments) {
      return [...equipments].sort((e1, e2) => {
        if (e1.name < e2.name) return -1;
        if (e1.name > e2.name) return 1;
      });
    },

    managementsConnectionOpen(management) {
      this.managementsConnection = true;
      setTimeout(() => {
        this.$refs.managementsConnection.active(management);
      }, 100);
    },

    managementsConnectionClose() {
      this.managementsConnection = false;
    },

    managementsMetadatasOpen(management, action) {
      this.managementsMetadatas = true;
      setTimeout(() => {
        this.$refs.managementsMetadatas.active(management, action);
      }, 100);
    },

    managementsMetadatasClose() {
      this.managementsMetadatas = false;
      // this.dashboard(() => {
      //   this.managementsMetadatas = false;
      // });
    },

    managementsShareOpen(management) {
      this.managementsShare = true;
      setTimeout(() => {
        this.$refs.managementsShare.active(management);
      }, 200);
    },

    managementsShareClose() {
      this.managementsShare = false;
    },

    managementsSharedOpen() {
      this.managementsShared = true;
    },

    managementsSharedClose() {
      this.dashboard(() => {
        this.managementsShared = false;
      });
    },

    managementsFirmwaresOpen(management) {
      this.managementsFirmwares = true;
      setTimeout(() => {
        this.$refs.managementsFirmwares.active(management);
      }, 100);
    },

    managementsFirmwaresClose() {
      this.managementsFirmwares = false;
    },

    managementsPricesOpen(management) {
      this.managementsPrices = true;
      setTimeout(() => {
        this.$refs.managementsPrices.active(management);
      }, 100);
    },

    managementsPricesClose() {
      this.managementsPrices = false;
    },

    equipmentsConnectionOpen(equipment) {
      this.equipmentsConnection = true;
      setTimeout(() => {
        this.$refs.equipmentsConnection.active(equipment);
      }, 100);
    },

    equipmentsConnectionClose() {
      this.equipmentsConnection = false;
    },

    equipmentsMetadatasOpen(equipment) {
      this.equipmentsMetadatas = true;
      setTimeout(() => {
        this.$refs.equipmentsMetadatas.active(equipment);
      }, 100);
    },

    equipmentsMetadatasClose() {
      this.equipmentsMetadatas = false;
      // this.dashboard(() => {
      //   this.equipmentsMetadatas = false;
      // });
    },

    equipmentsFirmwareOpen(equipment) {
      this.equipmentsFirmware = true;
      setTimeout(() => {
        this.$refs.equipmentsFirmware.active(equipment);
      }, 100);
    },

    equipmentsFirmwareClose(args) {
      if (args.target != undefined) {
        console.log(this.$options.name, "equipmentsFirmwareClose", args);
        OCPP_WS.publish(args.target, args.message);
      }

      this.equipmentsFirmware = false;
    },

    equipmentsPriceOpen(equipment) {
      this.equipmentsPrice = true;
      setTimeout(() => {
        this.$refs.equipmentsPrice.active(equipment);
      }, 100);
    },

    equipmentsPriceClose() {
      this.equipmentsPrice = false;
    },

    dashboard(callback) {
      OCPP_API.equipments.search({ owner: true }).then((r1) => {
        console.log("dashboard", "equipments", r1.data.page.totalElements);

        let equipments = r1.data._embedded.equipments;
        OCPP_API.managements.search({ owner: true }).then((r2) => {
          console.log("dashboard", "managements", r2.data.page.totalElements);

          r2.data._embedded.managements.forEach((m) => {
            let management = this.managements.find((i) => i.id === m.id);

            if (management == undefined) {
              m.equipments = [];
              m.managementsConnection = false;
              this.managements.push(m);
              management = m;
              OCPP_WS.refresh(management);
            } else {
              management.attributes = m.attributes;
              management.name = m.name;
              management.description = m.description;
            }
            equipments.forEach((e) => {
              if (e.management.id === management.id) {
                const equipment = management.equipments.find(
                  (i) => i.id === e.id
                );
                if (equipment == undefined) {
                  e.state = "grey darken-3";
                  e.status = "grey darken-3";
                  e.statusMsg = "";
                  if (e.attributes == null) {
                    e.attributes = {};
                  }
                  management.equipments.push(e);
                } else {
                  equipment.attributes = e.attributes;
                  equipment.name = e.name;
                  equipment.description = e.description;
                }
              }
            });
          });
          // if (this.Utils.cardHead() && this.managements.length > 0) {
            // const today = this.$moment();
            // const params2 = {
            //   query: {
            //     searchStart: this.Utils.chartStart(today),
            //     searchEnd: this.Utils.chartEnd(today),
            //     state: "ENDED",
            //   },
            //   page: 1,
            //   itemsPerPage: 100000000,
            //   sortBy: ["seq"],
            //   sortDesc: ["desc"],
            // };

            // OCPP_API.events.search(params2).then((r3) => {
            //   console.log("OCPP_API.events.search 받아온 충전내역 정보", r3);
            //   let events = r3.data._embedded.events;

            //   let managementLabels = [];
            //   let managementKwhs = [];
            //   let managementPrices = [];
            //   this.managements.forEach((m) => {
            //     managementLabels.push("충전소[" + m.name + "] 전체");
            //     managementKwhs.push(0);
            //     managementPrices.push(0);
            //   });
            //   events.forEach((e) => {
            //     const managementLabel =
            //       "충전소[" + e.equipment.management.name + "] 전체";
            //     const managementIdx = managementLabels.findIndex(
            //       (i) => i === managementLabel
            //     );
            //     if (managementIdx > -1) {
            //       managementKwhs[managementIdx] =
            //         managementKwhs[managementIdx] + e.kw;
            //       managementPrices[managementIdx] =
            //         managementPrices[managementIdx] + e.price;
            //     }
            //   });
            //   this.managementKwhsCollection = Utils.chartCollection(
            //     "총 충전량",
            //     managementLabels,
            //     managementKwhs
            //   );
            //   this.managementKwhsOption = Utils.chartOption("kwh");
            //   this.managementPricesCollection = Utils.chartCollection(
            //     "총 충전 금액",
            //     managementLabels,
            //     managementPrices
            //   );
            //   this.managementPricesOption = Utils.chartOption("원");
            // });
          // }
          callback();
        });
      });
    },

    findEquipmentByEvent(e) {
      const path = e.message.target.split("/");
      const management = this.managements.find((i) => i.id === path[2]);
      if (management != undefined) {
        const equipment = management.equipments.find(
          (i) => i.client === path[3]
        );
        if (equipment != undefined) {
          return equipment;
        }
      }
      return null;
    },

    onStartup(e) {
      console.log("onStartup: ", e);
      this.dashboard(() => {});
    },

    onShutdown(e) {
      console.log("onShutdown: ", e);
    },

    onConnect(e) {
      console.log("onConnect", e);

      this.dashboard(() => {
        this.updateErrorToast(e);
        const equipment = this.findEquipmentByEvent(e);
        this.updateEquipmentState(e, equipment, true);
        this.updateEquipmentStateus(e, equipment);
      });
    },

    onDisconnect(e) {
      console.log("onDisconnect", e);
      this.dashboard(() => {
        this.updateErrorToast(e);
        const equipment = this.findEquipmentByEvent(e);
        this.updateEquipmentState(e, equipment, false);
      });
    },

    onError(e) {
      console.log("onError: ", e);
    },

    // 1.
    onSend(e) {
      console.log("onSend: ", e);
      this.updateErrorToast(e);
    },

    // 2.
    onState(e) {
      console.log("onState: ", e);
      this.updateErrorToast(e);
      const equipment = this.findEquipmentByEvent(e);
      this.updateEquipmentState(e, equipment, true);
      this.updateEquipmentStateus(e, equipment);
    },

    // 3.
    onHandle(e) {
      console.log("onHandle: ", e);
      this.updateErrorToast(e);
    },

    // 4.
    onReceived(e) {
      console.log("onReceived: ", e);
      this.updateErrorToast(e);
    },

    updateErrorToast(e) {
      // let body = e.message.message;
      let body = e.message;
      if (undefined != body["error"]) {
        const t = JSON.stringify(body);
        this.$toast.error(t);
        return;
      }

      body = e.message.error;
      if (undefined != body && "" != body) {
        const t = JSON.stringify(body);
        this.$toast.warning(t);
      }
    },

    /**
     * state : 충전시 모양 chip 색
     * status & statusMsg : 카드 태깅 시 chip 색, 설명(Accepted)
     */
    updateEquipmentState(e, equipment, onoff) {
      if (e == null || equipment == null) return;
      this.$set(equipment, "state", onoff ? "green" : "grey darken-3");
      this.$set(equipment, "status", "grey darken-3");
      this.$set(equipment, "statusMsg", "");
    },

    /** 카드 태깅 시 chip 색, 설명 */
    updateEquipmentStateus(e, equipment) {
      if (e == null || equipment == null) return;

      const body = e.message.message;
      if (body["state"] == undefined) return;

      const state = body["state"];
      const status = body["status"];

      // if ("ocpp2.0.1/BootNotification" == state) {
      if (state.includes("BootNotification")) {
        this.$set(equipment, "status", "green");
        this.$set(equipment, "statusMsg", status);
        console.log(status);
      // } else if ("ocpp2.0.1/FirmwareStatusNotification" == state) {
      } else if (state.includes("FirmwareStatusNotification")) {
        this.$set(equipment, "status", "amber");
        this.$set(equipment, "statusMsg", status);
        console.log(status);
      // } else if ("ocpp2.0.1/UpdateFirmware" == state) {
      } else if (state.includes("UpdateFirmware")) {
        this.$set(equipment, "status", "amber");
        this.$set(equipment, "statusMsg", status);
        console.log(status);
      // } else if ("ocpp2.0.1/StatusNotification" == state) {
      } else if (state.includes("StatusNotification")) {
        this.$set(equipment, "status", "green");
        this.$set(equipment, "statusMsg", status);
        console.log(status);
      // } else if ("ocpp2.0.1/Authorize" == state) {
      } else if (state.includes("Authorize")) {
        this.$set(equipment, "status", "purple");
        this.$set(equipment, "statusMsg", status);
        console.log(status);
      // } else if ("ocpp2.0.1/TransactionEvent" == state) {
      } else if ("ocpp2.0.1/TransactionEvent" == state || "ocpp1.6/MeterValues" == state) {
        this.$set(equipment, "status", "red");
        console.log(status); // {currency: '원', eventType: 'Started', kw: 0, totalCost: 0}

        // Updated or Ended
        if (status.eventType !== "Started") {
          // 소수점 아래 반올림
          status.totalCost = Number.parseInt(status.totalCost);

          // 천 단위로 콤마 정규식
          status.totalCost = status.totalCost
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

          const status_rev =
            status.eventType +
            " " +
            status.kw +
            "kwh / " +
            status.totalCost +
            status.currency;

          this.$set(equipment, "statusMsg", status_rev);
          console.log(status_rev);
        } else {
          // Started
          this.$set(equipment, "statusMsg", status.eventType);
        }
      }
    },
  },

  mounted() {
    console.log("0. Dashboard 마운트");
    // 실행하는게 아니라 매핑해놓음
    OCPP_WS.$on("onStartup", this.onStartup);
    OCPP_WS.$on("onShutdown", this.onShutdown);

    OCPP_WS.$on("onConnect", this.onConnect);
    OCPP_WS.$on("onDisconnect", this.onDisconnect);
    OCPP_WS.$on("onReceived", this.onReceived);
    OCPP_WS.$on("onSend", this.onSend);
    OCPP_WS.$on("onError", this.onError);
    OCPP_WS.$on("onHandle", this.onHandle);
    OCPP_WS.$on("onState", this.onState);

    // 실제 실행이 됨
    console.log("1. OCPP_WS.connect() EventBus 통해 onStartup 메소드 실행");
    OCPP_WS.connect();

    // setTimeout(() => {
    //       // var today = this.$moment().format("YYYY-MM-DD");

    //   this.$refs.statisticsCharts.active("2021-12-01", "2021-12-31");
    // }, 100);
  },

  beforeDestroy() {
    OCPP_WS.$off("onStartup");
    OCPP_WS.$off("onShutdown");

    OCPP_WS.$off("onConnect");
    OCPP_WS.$off("onDisconnect");
    OCPP_WS.$off("onReceived");
    OCPP_WS.$off("onSend");
    OCPP_WS.$off("onError");
    OCPP_WS.$off("onHandle");
    OCPP_WS.$off("onState");
  },
};
</script>

<style></style>
